.Anchors {
  &__nav {
    @include flex(center, flex-start);
    gap: 20px;
    margin-top: 30px;

    @include responsive($tabletSmall) {
      gap: get-vw(20);
    }
  }
}
.Page__content{
  padding-top: 0;
  max-width: 1210px;
  padding: 0 20px;
  margin: auto;

  @include responsive($tabletSmall) {
    @include flex(initial,space-between);
  }

  &.--home {
    max-width: 955px;
    margin: auto;

    .container {
      padding: 0 20px;
    }

    .full--img {
      img {
        margin: 10px auto 80px;

        @include responsive($tabletSmall) {
          margin: get-vw(10) auto get-vw(80);
        }
      }
    }

    .wswyg--content {
      h2 {
        font-weight: 24px!important;
        color: $blue;
        max-width: 680px;
        margin: 40px auto;
        text-align: center;
  
        @include responsive($tabletSmall) {
          margin: get-vw(50) auto;
          @include font-vw(24);
        }
      }

      img {
        &[align="left"] {
          margin-right: 50px;
  
          @include responsive($tabletSmall) {
            margin-right: get-vw(50);
          }
        }

        @include responsive($tabletSmall, max) {
          width: 100%;
        }
      }
    }
  }

  .Single__aside {
    flex-basis: 28%;

    @include responsive($tabletSmall, max) {
      position: sticky;
      top: 76px;
      background: $white;
      z-index: 1;
      padding-top: 20px;
    }

    a {
      display: block;
      color: #2d447b;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: .11em;
      text-transform: uppercase;
      opacity: 0.6;

      @include responsive($tabletSmall, max) {
        white-space: nowrap;
      }

      &:not(:first-child) {
        @include responsive($tabletSmall) {
          margin-top: get-vw(25);
        }
      }
    }

    .is--active {
      opacity: 1;
      @include flex;
      gap: 3px;

      @include responsive($tabletSmall) {
        @include font-vw(20);
        line-height: 1.1;

        &:before {
          content : url('../../dist/imgs/arrow.svg');
          width: 24px;
          height: 24px;
        }
      }
    }

    .Single__nav {
      position: sticky;
      top: 120px;
      padding-right: 25px;

      @include responsive($tabletSmall, max) {
        overflow-x: scroll;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        gap: 30px;
        padding-bottom: 20px;
      }

      @include responsive($tabletSmall) {
        overflow-y: scroll;
        padding-right: get-vw(25);
        height: 60vh;
        border-right: 1px solid #e5e5e5;
      }
    }
  }

  .Single__content {
    padding: 0;
    flex-basis: 72%;

    h2 {
      color: $blue;
      font-weight: 700;
      font-size: 40px;
      
      @include responsiveMinMax($tabletSmall, $deskXL) {
        @include font-vw(40);
      }
    }

    .container {
      @include responsive($tabletSmall, max) {
        padding: 40px 10px;
      }
    }
  }
}
.home {
  svg {
    height: 13px;
    width: 13px;

    @include responsive($menuBreakpoint, max) {
      height: 20px;
      width: 20px;
    }

    > path {
      fill: $blue;
      filter: opacity(0.3);
      transition: filter 0.5s $ease;
  
      &:hover {
        filter: opacity(1);
      }

      @include responsive($menuBreakpoint, max) {
        filter: opacity(1);
      }
    }
  }
}

.Nav {
  &__container {
    pointer-events: all;
    color: $blue;
    @include flex(center, initial);
    gap: 30px;

    .--animating & {
      pointer-events: none;
    }

    @include responsive($menuBreakpoint, max) {
      @include flex(center, center);
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $base;
      color: $white;
      overflow-y: auto;
      flex-direction: column;
    }

    body:not(.showMenu) & {
      @include responsive($menuBreakpoint, max) {
        display: none;
      }
    }
  }

  &__main {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include responsive($menuBreakpoint, max) {
      padding: 0 40px 100px;
      width: 100%;
    }
  }

  &__item {
    text-align: center;

    @include responsive($menuBreakpoint) {
      display: inline-block;
    }

    & + & {
      @include responsive($menuBreakpoint) {
        margin-left: 25px;
      }
    }
  }

  &__link {
    position: relative;
    filter: opacity(0.3);
    transition: filter 400ms $easeOutQuad;

    @include responsive($menuBreakpoint, max){
      font-size: 24px;
      filter: opacity(1);
    }

    &.--active,
    &:focus,
    &:hover {
      filter: opacity(1);

      &:after {
        transform: scaleX(1);
      }
    }

    &.--active {
      cursor: not-allowed;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $blue;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 400ms $easeOutQuad;

      html.--dark & {
        background: $white;
      }

      body.showMenu & {
        @include responsive($menuBreakpoint, max) {
          background: $white;
        }
      }
    }
  }
}

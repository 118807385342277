.Loader {
  @include cover(fixed);
  z-index: 100;
  background: $white;
  visibility: hidden;
  overflow: hidden;

  html.--dark & {
    background: $base;
  }

  body.--intro & {
    visibility: hidden !important;
  }

  body.--animating & {
    visibility: visible;
  }
}

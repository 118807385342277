$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background: white;

  .container {
    width: 100%;
    @include flex(center, space-between);
    max-width: 1210px;
    padding: 10px 20px;
    margin: auto;

    .--home & {
      @include responsive($menuBreakpoint) {
        flex-direction: column;
        gap: 20px;

        .Nav__link {
          filter: opacity(1)
        }

        svg > path {
          filter: opacity(1)
        }
      }
    }
  }
}

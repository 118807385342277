.Footer {
   background: $base;
   color: $blue;
   position: relative;
   bottom:0;
   left:0;
   z-index:1;
   width:100%;
   border-top: 1px solid #e5e5e5;
   margin-top: 90px;

   @include responsive($tabletSmall) {
      margin-top: get-vw(90);
   }

   .container {
      padding-top: 25px;
      padding-bottom: 25px;
      max-width: 1210px;
      padding: 0 20px;
      margin: auto;

      @include responsive($tabletSmall) {
         @include flex(center, space-between);
         padding-top: get-vw(25);
         padding-bottom: get-vw(25);
      }
   }

   &__top {
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;

      .container {
         @include responsive($tabletSmall, max) {
           padding: 30px 0;
           @include flex;
           flex-direction: column
         }

         @include responsive($tabletSmall) {
            @include flex(center,space-between);
            gap: 30px;
         }

         .flex {
            @include flex(center,center);
            gap: 20px;

            @include responsive($tabletSmall, max) {
              order: 2;
            }

            .socials {
               @include flex(center,center);
               gap: 30px;

               li {
                  position: relative;

                  &:not(:first-child) {
                     &::before {
                        content: "•";
                        pointer-events: none;
                        position: absolute;
                        top: 0px;
                        left: -19px;
                    }
                  }

                  a {
                     opacity: 1;
                  }
               }
            }
         }

         .image {
            @include responsive($tabletSmall, max) {
               margin-bottom: 20px
            }
         }
      }
   }

   &__bottom {
      font-size: 12px;

      @include responsive($tabletSmall) {
        @include font-vw(12)
      }

      .container {
         padding-top: 0;
         justify-content: center;
      }

      ul {
         position: relative;

         @include responsive($tabletSmall) {
            @include flex(center,center);
            gap: 30px;
            // gap: get-vw(60);
            // left: 79%;
         }

         li {
            @include flex(center,center);

            @include responsive($tabletSmall, max) {
              flex-direction: column;
            }

            @include responsive($tabletSmall) {
               gap: 30px;
            }

            a {
               opacity: 1;
               font-size: 12px;

               @include responsiveMinMax($tabletSmall, $deskXL) {
                  @include font-vw(12);
               }
            }
         }
      }
   }
}

@font-face {
  font-family: Aiglon;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AiglonAaa-Regular.woff2') format("woff2"), url('../fonts/AiglonAaa-Regular.woff') format("woff");
}

@font-face {
  font-family: Aiglon;
  font-weight: 700;
  font-style: bold;
  font-display: block;
  src: url('../fonts/AiglonAaa-ExtraBold.woff2') format("woff2"), url('../fonts/AiglonAaa-ExtraBold.woff') format("woff");
}

@font-face {
  font-family: nhd;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/NeueHaasDisplayRoman.woff2') format("woff2"), url('../fonts/NeueHaasDisplayRoman.woff') format("woff");
}

@font-face {
  font-family: nhd;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/NeueHaasDisplayMedium.woff2') format("woff2"), url('../fonts/NeueHaasDisplayMedium.woff') format("woff");
}
#Outdated {
  @include flex(center, center);
  @include cover(fixed);
  z-index: 2400;
  background: $base;

  &:not(.show) {
    display: none;
  }

  .inner {
    color: $white;
    text-align: center;
  }

  h4 {
    margin-bottom: 0px;
  }

  .Btn {
    background: $white;

    .Btn__txt {
      color: $base;
    }
  }
}

h1, h2, h3, h4, h5, h6{
   font-weight: 500;
}

h1 {
   line-height: 1.05;
   margin-bottom: 0;
   font-size: 16vw;

   @include responsive($tabletSmall) {
      @include font-vw(100);
   }

   .subtitle + & {
      margin-top: 0.1em;
   }

   > .--oh{
      &:not(:first-child){
         margin-top: -0.15em;
      }
   }
}

h2 {
   font: 400 32px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(32);
   }
}

h3 {
   font: 400 24px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(24);
   }
}

h4 {
   font: 400 22px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(22);
   }
}

h5 {
   font: 400 20px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(20);
   }
}

h6 {
   font: 400 18px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(18);
   }
}

sup {
   vertical-align: top;
   filter: opacity(0.95);

   @include responsive($tabletSmall, max) {
      font-size: 50px;
   }

   @include responsive($tabletSmall) {
      @include font-vw(50);
   }
}

.subtitle {
   filter: opacity(0.5);
   letter-spacing: -0.03em;
   font-size: 20px;

   @include responsive($tabletSmall) {
      @include font-vw(20);
   }
}

strong {
   font-weight: 500;
}

p {
   font-size: 16px;
   line-height: 1.6;

   @include responsive($tabletSmall){
     @include font-vw(16);
   }

   &.no__results {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      opacity: 0.2;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }
   }
}

.wswyg--content {
   text-align: left;
   line-height: 1.5;

   * { 
      color: inherit; 
      margin-bottom: 24px;
   }

   div {
      font-size: 16px;

      @include responsive($tabletSmall) {
         @include font-vw(16);
      }
   }

   sup {
      vertical-align: super;
      font-size: 12px;

      @include responsive($tabletSmall) {
        @include font-vw(12);
      }
   }

   table, table p, table ul li {
      font-size: 12px;

      @include responsive($tabletSmall) {
        @include font-vw(12);
      }
   }

   table, th, td {
      border: 1px solid black;

      ul {
         padding-left: 15px
      }
   }

   td {
      padding: 10px;
   }

   tr:first-child {
      background: #d9d8d8;
   }

   strong {
      font-weight: 700;
   }

   h2 {
      letter-spacing: .11em;
      font-size: 24px;

      &:not(:first-child) {
         margin-top: 80px;
   
         @include responsive($tabletSmall){
            margin-top: get-vw(80);
         }
      }
   }

   ul,
   ol {
      padding-left: 30px;

      @include responsive($tabletSmall){
         padding-left: get-vw(30);
      }

      li {
         @include responsive($tabletSmall){
            @include font-vw(16);
         }
      }

      > li + li {
         margin-top: 1em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(1) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding: 20px;
      margin: 40px 0;
      background-color: #2d457B0A;

      @include responsive($tabletSmall){
         padding: get-vw(40);
         margin: get-vw(80) 0;
      }

      p {
         margin-bottom: 24px;

         &:last-child {
            margin: 0;
         }
      }
   }

   a {
      color: $links-color;
      font-weight: bold;
      text-decoration: underline;
      word-break: break-word;
      word-wrap: break-word;
   }
}

.Page__header{
  .banner {
    position: relative;
    padding: 80px 0;

    @include responsive($tabletSmall) {
      padding: get-vw(80) 0;
    }

    &::after {
      content: '';
      background: $black;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      position: absolute;
      top: 0;
    }
  }

  .container{
    color: white;
    position: relative;
    z-index: 1;
    text-align: center;
    max-width: 1170px;
    margin: auto;
    padding: 0 20px;

    @include responsive($tabletSmall){
      max-width: get-vw(1170)
    }

    h1{
      font-size: 40px;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: .11em;
      margin-bottom: 45px;

      @include responsive($tabletSmall) {
        margin-bottom: get-vw(45);
      }
    }

    p {
      font-weight: 700;
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 40px;
        align-self: start;
      }

      @include responsive($tabletSmall){
        align-self: end;
        margin-left: auto;
      }
    }
  }
}
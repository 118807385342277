/* -----
Paths
----- */

$base-images: "../images/";
$base-fonts: "../fonts/";

/* -----
Colors
----- */
$white: #ffffff;
$black: #111111;
$grey: #999999;
$grey-l: #eeeeee;
$gold: #af904d;
$base: $white;
$base-d: $white;
$blue: #2d457b;
$links-color: $blue;
$error-color: #dc3838;
$valid-color: #5da854;

/* -----
Fonts
----- */

$main-font: Aiglon, sans-serif;

/* -----
Grid
----- */

$base-vw: 1440;
$base-grid: 24;
$browser-context: 20;

/* -----
CSS Easings
----- */

$transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;

.Btn {
  @include flex(center, center);
  text-align: center;
  background: $blue;
  color: $white!important;
  padding: 16px 24px;
  margin: 60px auto;
  outline: 0;
  border: 0;
  cursor: pointer;
  max-width: max-content;

  html.--dark & {
    background: $white;
    color: $base!important;
  }

  @include responsive($tabletSmall) {
    padding: get-vw(16) get-vw(24);
    margin-top: get-vw(20);
  }

  &__txt {
    display: block;
    text-transform: uppercase;
    color: inherit;
    font-weight: bold;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.1em;
    position: relative;

    @include responsive($tabletSmall) {
      @include font-vw(13);
    }
  }
}
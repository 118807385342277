
.tooltip {
  text-transform: none;
  letter-spacing: 0em;
  position: relative;
  font-weight: normal;
  @include flex(center, center, inline-flex);
  border: 1px solid $grey-l;
  color: $grey;
  background: $grey-l;
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  border-radius: 50%;
  padding-top: 0.1em;
  top: -0.2em;
  cursor: pointer;

  @include responsive($tabletSmall) {
    min-width: get-vw(16);
    line-height: get-vw(16);
    height: get-vw(16);
    @include font-vw(10);
    border-width: get-vw(1);
  }

  @include noTouchHover() {
    .tooltip__content {
      transform: translate(-50%, 0%);
      opacity: 1;
    }
  }

  html.--dark & {
    border-color: $grey;
    background: $base;
  }

  &__content {
    opacity: 0;
    cursor: initial;
    position: absolute;
    left: 50%;
    bottom: 100%;
    white-space: nowrap;
    background: $base;
    border: 1px solid $base;
    margin-bottom: 10px;
    transform: translate(-50%, -4px);
    color: $white;
    padding: 4px 12px;
    z-index: 20;
    font-size: 12px;
    pointer-events: none;
    transition: transform 420ms $ease, opacity 420ms $ease;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(10);
      @include font-vw(12);
      padding: get-vw(4) get-vw(12);
      transform: translate(-50%, get-vw(4));
      border-width: get-vw(1);
    }

    html.--dark & {
      border-color: $white;
      background: $base;
    }

    &:after {
      @include content();
      width: 8px;
      height: 8px;
      border: 1px solid $base;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      background: $base;
      position: absolute;
      top: 100%;
      left: 52%;
      transform: translate(-50%, -50%) rotate(45deg);

      @include responsive($tabletSmall) {
        width: get-vw(8);
        height: get-vw(8);
        border-width: get-vw(1);
      }

      html.--dark & {
        border-color: $white;
        background: $base;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
      }
    }
  }
}
.Brand {
  display: flex;
  position: relative;
  z-index: 10;
  pointer-events: all;
  margin-right: auto;
  overflow:hidden;

  .logo {
    svg {
      margin-right: 24px;
      max-width: 76px;
    }

    img {
      border-left: 1px solid #e5e5e5;
      padding-left: 24px;
      max-height: 56px;
    }
  }

  .Intro & {
    margin-left: auto;

    svg > * {
      fill: $white;
    }
  }
}
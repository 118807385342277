.ToolCatalogue {
    padding: 0 20px;

    .dataTables_filter {
        margin-bottom: 40px;
    
        @include responsive($tabletSmall) {
            margin-bottom: get-vw(40);
        }
    }

    label {
        text-transform: uppercase;
        color: $blue;
        font-size: 12px;
        font-weight: 700;
    }

    table {       
            font-size: 12px;

            thead {
                background-color: #c8d1e3;
                font-weight: 700;

                th {
                    transition: background-color 0.6s $ease, color 0.6s $ease;
                    min-width: 130px;
                    line-height: 1.2;
                    vertical-align: middle;

                    &:hover {
                        background-color: $blue;
                        color: $white
                    }
                }
            }
    
            tbody {
                tr {
                    padding: 10px;
    
                    &:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                }

                td {
                    word-break: break-word;
                }
            }

            th.column-1 {
                min-width: 60px;
            }

            td.column-5, th.column-5, td.column-13, th.column-13, td.column-16, th.column-16, td.column-17, th.column-17 {
                min-width: 400px;
            }

            td.column-8, th.column-8, td.column-9, th.column-9 {
                min-width: 700px;
            }
    }

    .Btn {
        margin: 50px auto;

        @include responsive($tabletSmall) {
            margin: get-vw(50) auto;
        }
    }
}
.Form {

  fieldset {
    &+fieldset {
      margin-top: 80px;
      @include responsiveMinMax($tabletSmall, $deskXL) {
        margin-top: get-vw(80);
      }
    }
      
    legend {
      margin-bottom: 40px;
      font-size: 2rem;
      font-weight: 600;
      
      @include responsiveMinMax($tabletSmall, $deskXL) {
        margin-bottom: get-vw(40);
        @include font-vw(20);
      }
    }
  }

  .row {
    @include flex(flex-end, flex-start);
    flex-wrap: wrap;
    gap: 30px;

    @include responsive($tabletSmall) {
      gap: get-vw(30);
    }

    > * {
      width: 100%;

      @include responsive($tabletSmall) {
        max-width: calc(50% - get-vw(15));
      }

      &.--full {
        @include responsive($tabletSmall) {
          max-width: 100%;
        }
      }
    }
  }

  label:not(.Checkbox) {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 8px;
    padding: 0;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(8);
      @include font-vw(12);
    }
  }

  &__group {
    position: relative;
    width: 100%;

    span.--error {
      font-size: 10px;
      padding-top: 2px;
      
      @include responsive($tabletSmall) {
        padding-top: get-vw(2);
        @include font-vw(10);
      }
    }
  }

  &__control {
    width: 100%;
    min-height: 42px;
    padding: 0 16px;
    border-radius: 0;
    outline: 0;
    border: 1px solid $grey-l;
    box-shadow: none;
    font-size: 16px;
    transition: background 240ms $ease, border 240ms $ease;

    @include responsive($tabletSmall) {
      border-width: get-vw(1);
      min-height: get-vw(42);
      padding: 0 get-vw(16);
      @include font-vw(16);
    }

    @include noTouchHover() {
      border: 1px solid darken($grey, 5%);
    }

    html.--dark & {
      border-color: $grey;
    }

    &[disabled] {
      opacity: 0.3;
      pointer-events: none;
      background: $grey-l;

      html.--dark & {
        background: $grey;
      }
    }

    @include responsive($tabletSmall) {
      min-height: get-vw(42);
      border-width: get-vw(1);
      padding: 0 get-vw(14);
    }

    .--error & {
      border-color: $error-color;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  select.Form__control {
    line-height: 42px;
    height: 42px;

    @include responsive($tabletSmall) {
      line-height: get-vw(42);
      height: get-vw(42);
    }
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 30px;

    @include responsive($tabletSmall) {
      padding: get-vw(30);
      min-height: get-vw(200);
      max-height: get-vw(200);
    }
  }

  &__actions {
    margin-top: 40px;

    @include responsive($tabletSmall) {
      margin-top: get-vw(40);
    }

    .Btn {
      margin-top: 0;
    }
  }

  &__output {
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;

      @include responsive($tabletSmall) {
        margin-top: get-vw(20);
      }
    }
  }
}

.Radios,
.Checkboxes {
  @include flex(center, flex-start, inline-flex);
  width: 100%;
  gap: 40px;

  @include responsive($tabletSmall) {
    gap: get-vw(40);
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  &.--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: $base;

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border: 1px solid $base;
    border-radius: 2px;
    margin-right: 10px;
    transition: background 240ms $easeOutQuad;

    @include responsive($tabletSmall) {
      min-width: get-vw(24);
      max-width: get-vw(24);
      height: get-vw(24);
      border-width: get-vw(1);
      border-radius: get-vw(2);
      margin-right: get-vw(10);
    }

    html.--dark & {
      border-color: $white;
    }

    .--radio-group & {
      border-radius: 18px;

      @include responsive($tabletSmall) {
        border-radius: get-vw(18);
      }

      &:after {
        @include content;
        width: 12px;
        height: 12px;
        background: $white;
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $easeOutQuad;

        @include responsive($tabletSmall) {
          width: get-vw(12);
          height: get-vw(12);
          border-radius: get-vw(20);
        }
      }
    }

    .check {
      position: relative;
      width: 50%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: $white;
          stroke-width: 6px;
          stroke-linecap: round;

          @include responsive($tabletSmall) {
            stroke-width: get-vw(6);
          }
        }
      }
    }
  }

  &__label {
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 500;

    @include responsive($tabletSmall) {
      @include font-vw(16);
    }
  }
}
